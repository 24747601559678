// Below are routes that use simple template.
import i18n from '../../plugins/i18n';

export default [
  {
    path: '/simple',
    component: () => import('@/templates/Simple.vue'),
    children: [
      {
        path: '/allegroAuthorization',
        alias: '/',
        name: 'allegroAuthorization',
        meta: {
          title: 'Autoryzacja Allegro',
          name: 'Autoryzacja',
          submenu: false,
        },
        component: () => import('@/views/Speditors/Allegro/AllegroAuthorization.vue'),
      },
      {
        path: '/upsAuthorization',
        alias: '/',
        name: 'upsAuthorization',
        meta: {
          title: 'Autoryzacja UPS',
          name: 'Autoryzacja',
          submenu: false,
        },
        component: () => import('@/views/Speditors/UPS/UPSAuthorization.vue'),
      },
      {
        path: '/InPost/new',
        name: 'user-configuration-set-inpost-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - InPost`,
        },
        component: () => import('@/views/Speditors/InPost/InPost.vue'),
        props: true,
      },
      {
        path: '/InPost/:confSetId',
        name: 'user-configuration-set-inpost-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - InPost`,
        },
        component: () => import('@/views/Speditors/InPost/InPost.vue'),
        props: true,
      },
      {
        path: '/DPD/new',
        name: 'user-configuration-set-dpd-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - DPD`,
        },
        component: () => import('@/views/Speditors/DPD/DPD.vue'),
        props: true,
      },
      {
        path: '/DPD/:confSetId',
        name: 'user-configuration-set-dpd-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - DPD`,
        },
        component: () => import('@/views/Speditors/DPD/DPD.vue'),
        props: true,
      },
      {
        path: '/DodajPaczke/new',
        name: 'user-configuration-set-dodajpaczke-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - DodajPaczke`,
        },
        component: () => import('@/views/Speditors/DodajPaczke/DodajPaczke.vue'),
        props: true,
      },
      {
        path: '/DodajPaczke/:confSetId',
        name: 'user-configuration-set-dodajpaczke-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - DodajPaczke`,
        },
        component: () => import('@/views/Speditors/DodajPaczke/DodajPaczke.vue'),
        props: true,
      },
      {
        path: '/Fedex/new',
        name: 'user-configuration-set-fedex-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - Fedex`,
        },
        component: () => import('@/views/Speditors/Fedex/Fedex.vue'),
        props: true,
      },
      {
        path: '/Fedex/:confSetId',
        name: 'user-configuration-set-fedex-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - Fedex`,
        },
        component: () => import('@/views/Speditors/Fedex/Fedex.vue'),
        props: true,
      },
      {
        path: '/PocztaPolska/new',
        name: 'user-configuration-set-pocztapolska-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - Poczta Polska`,
        },
        component: () => import('@/views/Speditors/PocztaPolska/PocztaPolska.vue'),
        props: true,
      },
      {
        path: '/PocztaPolska/:confSetId',
        name: 'user-configuration-set-pocztapolska-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - Poczta Polska`,
        },
        component: () => import('@/views/Speditors/PocztaPolska/PocztaPolska.vue'),
        props: true,
      },
      {
        path: '/NoLimit/new',
        name: 'user-configuration-set-nolimit-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - NoLimit`,
        },
        component: () => import('@/views/Speditors/NoLimit/NoLimit.vue'),
        props: true,
      },
      {
        path: '/NoLimit/:confSetId',
        name: 'user-configuration-set-nolimit-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - NoLimit`,
        },
        component: () => import('@/views/Speditors/NoLimit/NoLimit.vue'),
        props: true,
      },
      {
        path: '/Raben/new',
        name: 'user-configuration-set-raben-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - Raben`,
        },
        component: () => import('@/views/Speditors/Raben/Raben.vue'),
        props: true,
      },
      {
        path: '/Raben/:confSetId',
        name: 'user-configuration-set-raben-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - Raben`,
        },
        component: () => import('@/views/Speditors/Raben/Raben.vue'),
        props: true,
      },
      {
        path: '/DHL/new',
        name: 'user-configuration-set-dhl-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - DHL`,
        },
        component: () => import('@/views/Speditors/DHL/DHL.vue'),
        props: true,
      },
      {
        path: '/DHL/:confSetId',
        name: 'user-configuration-set-dhl-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - DHL`,
        },
        component: () => import('@/views/Speditors/DHL/DHL.vue'),
        props: true,
      },
      {
        path: '/DHLParcelShop/new',
        name: 'user-configuration-set-dhlparcelshop-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - DHL ParcelShop`,
        },
        component: () => import('@/views/Speditors/DHLParcelShop/DHLParcelShop.vue'),
        props: true,
      },
      {
        path: '/DHLParcelShop/:confSetId',
        name: 'user-configuration-set-dhlparcelshop-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - DHL ParcelShop`,
        },
        component: () => import('@/views/Speditors/DHLParcelShop/DHLParcelShop.vue'),
        props: true,
      },
      {
        path: '/DHLDHLExpress/new',
        name: 'user-configuration-set-dhlexpress-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - DHL Express`,
        },
        component: () => import('@/views/Speditors/DHLExpress/DHLExpress.vue'),
        props: true,
      },
      {
        path: '/DHLExpressp/:confSetId',
        name: 'user-configuration-set-dhlexpress-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - DHL Express`,
        },
        component: () => import('@/views/Speditors/DHLExpress/DHLExpress.vue'),
        props: true,
      },
      {
        path: '/Geis/new',
        name: 'user-configuration-set-geis-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - Geis`,
        },
        component: () => import('@/views/Speditors/Geis/Geis.vue'),
        props: true,
      },
      {
        path: '/Geis/:confSetId',
        name: 'user-configuration-set-geis-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - Geis`,
        },
        component: () => import('@/views/Speditors/Geis/Geis.vue'),
        props: true,
      },
      {
        path: '/Suus/new',
        name: 'user-configuration-set-suus-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - Suus`,
        },
        component: () => import('@/views/Speditors/Suus/Suus.vue'),
        props: true,
      },
      {
        path: '/Suus/:confSetId',
        name: 'user-configuration-set-suus-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - Suus`,
        },
        component: () => import('@/views/Speditors/Suus/Suus.vue'),
        props: true,
      },
      {
        path: '/OrlenPaczka/new',
        name: 'user-configuration-set-orlenpaczka-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - Orlen Paczka`,
        },
        component: () => import('@/views/Speditors/OrlenPaczka/OrlenPaczka.vue'),
        props: true,
      },
      {
        path: '/OrlenPaczka/:confSetId',
        name: 'user-configuration-set-orlenpaczka-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - Orlen Paczka`,
        },
        component: () => import('@/views/Speditors/OrlenPaczka/OrlenPaczka.vue'),
        props: true,
      },
      {
        path: '/Allegro/new',
        name: 'user-configuration-set-allegro-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - Allegro`,
          parentRouteName: 'organizationConfigurationSets',
        },
        component: () => import('@/views/Speditors/Allegro/Allegro.vue'),
        props: true,
      },
      {
        path: '/Allegro/:confSetId',
        name: 'user-configuration-set-allegro-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - Allegro`,
        },
        component: () => import('@/views/Speditors/Allegro/Allegro.vue'),
        props: true,
      },
      {
        path: '/GLS/new',
        name: 'user-configuration-set-gls-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - GLS`,
        },
        component: () => import('@/views/Speditors/GLS/GLS.vue'),
        props: true,
      },
      {
        path: '/GLS/:confSetId',
        name: 'user-configuration-set-gls-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - GLS`,
        },
        component: () => import('@/views/Speditors/GLS/GLS.vue'),
        props: true,
      },
      {
        path: '/Pekaes/new',
        name: 'user-configuration-set-pekaes-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - Pekaes (Geodis)`,
        },
        component: () => import('@/views/Speditors/Pekaes/Pekaes.vue'),
        props: true,
      },
      {
        path: '/Pekaes/:confSetId',
        name: 'user-configuration-set-pekaes-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - Pekaes (Geodis)`,
        },
        component: () => import('@/views/Speditors/Pekaes/Pekaes.vue'),
        props: true,
      },
      {
        path: '/UPS/new',
        name: 'user-configuration-set-ups-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - UPS`,
        },
        component: () => import('@/views/Speditors/UPS/UPS.vue'),
        props: true,
      },
      {
        path: '/UPS/:confSetId',
        name: 'user-configuration-set-ups-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - UPS`,
        },
        component: () => import('@/views/Speditors/UPS/UPS.vue'),
        props: true,
      },
      {
        path: '/DBSchenker/new',
        name: 'user-configuration-set-dbschenker-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - DBSchenker`,
        },
        component: () => import('@/views/Speditors/DBSchenker/DBSchenker.vue'),
        props: true,
      },
      {
        path: '/DBSchenker/:confSetId',
        name: 'user-configuration-set-dbschenker-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - DBSchenker`,
        },
        component: () => import('@/views/Speditors/DBSchenker/DBSchenker.vue'),
        props: true,
      },
      {
        path: '/FedexDomestic/new',
        name: 'user-configuration-set-fedexdomestic-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - Fedex Domestic`,
        },
        component: () => import('@/views/Speditors/FedexDomestic/FedexDomestic.vue'),
        props: true,
      },
      {
        path: '/FedexDomestic/:confSetId',
        name: 'user-configuration-set-fedexdomestic-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - Fedex Domestic`,
        },
        component: () => import('@/views/Speditors/FedexDomestic/FedexDomestic.vue'),
        props: true,
      },
      {
        path: '/inny/new',
        name: 'user-configuration-set-customshipment-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - Inny sposób wysyłki`,
        },
        component: () => import('@/views/Speditors/Other/Other.vue'),
        props: true,
      },
      {
        path: '/inny/:confSetId',
        name: 'user-configuration-set-customshipment-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - Inny sposób wysyłki`,
        },
        component: () => import('@/views/Speditors/Other/Other.vue'),
        props: true,
      },
      {
        path: '/AmbroExpress/new',
        name: 'user-configuration-set-ambroexpress-new',
        meta: {
          title: `${i18n.t('sets.newSet')} - Ambro Express`,
        },
        component: () => import('@/views/Speditors/AmbroExpress/AmbroExpress.vue'),
        props: true,
      },
      {
        path: '/AmbroExpress/:confSetId',
        name: 'user-configuration-set-ambroexpress-edit',
        meta: {
          title: `${i18n.t('sets.editSet')} - Ambro Express`,
        },
        component: () => import('@/views/Speditors/AmbroExpress/AmbroExpress.vue'),
        props: true,
      },
    ],
  },
];
